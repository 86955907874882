<template>
  <b-card>
    <span
      v-if="loaderSpinner"
      class="d-flex justify-content-center align-items-center"
    >
      <b-spinner label="Spinning" />
    </span>
    <span v-if="!loaderSpinner">
      <b-row>
        <!-- SubCertificateNameEn
        SubCertificateNameAr -->
        <b-col cols="6">
          <b-form-group
            label="Certificate"
            label-for="Certificate"
          >
            <b-input
              :value="$route.params.Certificate_Name"
              disabled
              placeholder="Certificate"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Sub Certificate"
            label-for="Sub Certificate"
          >
            <b-input
              :value="`${SubCertificateNameEn} - ${SubCertificateNameAr}`"
              disabled
              placeholder="Sub Certificate"
            />
          </b-form-group>
        </b-col>

        <b-col md="6">
          <b-form-group
            label="Engineer"
            label-for="Engineer"
          >
            <v-select
              id="Engineer"
              :options="optionsEngineer"
              label="text"
              placeholder="Engineer"
              @input="addEngineersAndStatus"
            />
          </b-form-group>
        </b-col>
        <b-col cols="6" />
        <b-col
          v-for="(item, index) in addEngineers"
          :key="index"
          md="6"
        >
          <div class="border border-secondary p-1 rounded-lg m-1">
            <div class="d-flex align-items-center justify-content-between m-1 mb-md-0">
              <h1>{{ item.text }}</h1>
              <div @click="removeEngineer(index)">
                <feather-icon
                  icon="TrashIcon"
                  class="cursor-pointer"
                  color="red"
                  size="25"
                />
              </div>
            </div>

            <b-row>
              <b-col
                md="6"
                class="mt-2"
              >
                <b-form-group
                  label="Status"
                  label-for="Status"
                >
                  <b-form-checkbox-group
                    :id="`status_id_cer_${index}`"
                    v-model="item.statusesCer"
                    class="d-flex flex-column"
                    :options="statusesCer"
                    multiple
                    @change="addEngineersAndStatusToEdit(item)"
                  />
                </b-form-group>
              </b-col>

              <b-col
                md="6"
                class="mt-2"
              >
                <b-form-group
                  label=""
                  label-for=""
                >
                  <b-form-checkbox-group
                    :id="`status_id_eng_${index}`"
                    v-model="item.statusesEng"
                    class="d-flex flex-column"
                    :options="statusesEng"
                    multiple
                    @change="addEngineersAndStatusToEdit(item)"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          class="mt-50"
        >
          <b-button
            v-if="!loader && $route.params.id"
            variant="primary"
            class="mr-1"
            @click="saveEngineers()"
          >
            Save Changes
          </b-button>
          <b-button
            v-if="loader"
            variant="primary"
            disabled
            class="mr-1"
          >
            <b-spinner
              small
              type="grow"
            />
            Loading...
          </b-button>
        </b-col>
      </b-row>
    </span>
  </b-card>

</template>

<script>
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import decryptData from '@/store/auth/decryptData'

export default {
  components: { vSelect },
  data() {
    return {
      loader: false,
      loaderSpinner: false,
      optionsEngineer: [],
      addEngineers: [],
      statusesEng: [],
      statusesCer: [],
      dataToSend: [],
      SubCertificateNameEn: '',
      SubCertificateNameAr: '',
    }
  },
  mounted() {
    this.getEngineer()
    this.showEngineer()
    setTimeout(() => this.showEngOnCer(), 10)
  },
  methods: {
    async showEngOnCer() {
      try {
        this.loaderSpinner = true
        const response = await axios.get(`/certificate-with-statuses/${this.$route.params.id}`)
        const oldUserData = decryptData(response.data.payload).data.certificate

        this.SubCertificateNameEn = oldUserData.name.en
        this.SubCertificateNameAr = oldUserData.name.ar

        const mappedData = oldUserData.engineers.map(items => ({
          text: items.engineer_name,
          value: items.user_id,

          statusesCer: items.status
            .map(item => {
              const matchCer = this.statusesCer.find(status => status.text === item)
              return matchCer ? matchCer.value : null
            })
            .filter(Boolean),

          statusesEng: items.status
            .map(item => {
              const matchEng = this.statusesEng.find(status => status.text === item)
              return matchEng ? matchEng.value : null
            })
            .filter(Boolean),
        }))
        this.addEngineers = mappedData

        this.dataToSend = mappedData.map(item => ({
          statuses: [...item.statusesCer, ...item.statusesEng],
          engineer_id: item.value,
        }))
      } catch (error) {
        if (error.response.status) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `${error.response.data.message}`,
              icon: 'BellIcon',
              variant: 'error',
            },
          })
        }
      } finally {
        this.loaderSpinner = false
      }
    },
    async getEngineer() {
      try {
        this.loaderSpinner = true
        const res = await axios.get('certificate-status')
        const decryptedData = decryptData(res.data.payload).data.status.map(item => ({
          value: item.id,
          text: item.status,
        }))
        this.statusesEng = decryptedData.filter(status => [2, 10, 9, 5].includes(status.value))
        this.statusesCer = decryptedData.filter(status => ![2, 10, 9, 5].includes(status.value))
      } catch (error) {
        if (error.response.status) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `${error.response.data.message}`,
              icon: 'BellIcon',
              variant: 'error',
            },
          })
        }
      } finally {
        this.loaderSpinner = false
      }
    },
    showEngineer() {
      axios.get('https://gulftic-system.fci.group/api/engineers/list').then(res => {
        const userData = decryptData(res.data.payload).data.engineers
        this.optionsEngineer = userData.map(item => ({
          value: item.id,
          text: item.name,
        }))
      })
    },
    addEngineersAndStatus(item) {
      // Ensure each engineer has separate statuses
      this.addEngineers.push({
        ...item,
        statusesCer: [],
        statusesEng: [],
      })
    },
    removeEngineer(index) {
      this.addEngineers.splice(index, 1)
      this.dataToSend.splice(index, 1)
    },
    addEngineersAndStatusToEdit(item) {
      const newStatuses = [...item.statusesEng, ...item.statusesCer]
      const existingEngineer = this.dataToSend.find(entry => entry.engineer_id === item.value)
      if (existingEngineer) {
        existingEngineer.statuses = newStatuses
      } else {
        this.dataToSend.push({ statuses: newStatuses, engineer_id: item.value })
      }
      this.dataToSend = this.dataToSend.filter(entry => entry.statuses.length > 0)
    },
    saveEngineers() {
      this.loader = true
      const payload = {
        engineers: this.dataToSend.map(status => (status.engineer_id)),
        statuses: this.dataToSend.map(status => ({
          user_id: status.engineer_id,
          status_id: status.statuses,
        })),
      }

      axios
        .put(`/certificates/${this.$route.params.id}/engineers`, payload)
        .then(res => {
          if (res.status === 200) {
            // this.$router.push({ name: 'set-money' })
            this.$toasted.show('Updated Successfully', {
              position: 'top-center',
              duration: 3000,
            })
          }
        })
        .catch(error => {
          if (error.response.status) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `${error.response.data.message}`,
                icon: 'BellIcon',
                variant: 'error',
              },
            })
          }
        })
        .finally(() => {
          this.loader = false
        })
    },
  },
}
</script>
